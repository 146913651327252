<template>
  <div class="col-sm-12 col-md-12">
    <div class="panel panel-info">
      <div class="panel-heading">
        <h3 class="panel-title">
          {{ address.who }}'s {{$t('step3previousaddress', {lng: this.$store.getters.language}) }}: {{ address.id }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="form-grouppull-left">
          <input
            type="hidden"
            class="form-control"
            placeholder="id"
            v-model="id"
            :class="{danger: emptyName}"
          >
          <div class="checkbox">
            <label>
              <input v-model="checkednopreviousapartmentcoapplicant" @change="checkedNoPreviousApartmentCoApplicant($event)" type="checkbox"> {{$t('step3previousaddresscheckbox', {lng: this.$store.getters.language}) }}
            </label>
          </div>
          <div>
            <label for="previousapartmentnamecoapplicant"> {{previousapartmentnameplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="previousapartmentnameplaceholder"
              v-model="previousapartmentnamecoapplicant"
              id="previousapartmentnamecoapplicant"
              name="previousapartmentnamecoapplicant"
              @input="updateApartmentName"
              :class="{danger: emptyName}"
              data-vv-name="previousapartmentnamecoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousapartmentnamecoapplicant')">required</span>
          </div>
          <div>
            <label for="previousmanagersnamecoapplicant"> {{managersnameplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="managersnameplaceholder"
              v-model="previousmanagersnamecoapplicant"
              id="previousmanagersnamecoapplicant"
              name="previousmanagersnamecoapplicant"
              @input="updateManagersname"
              data-vv-name="previousmanagersnamecoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousmanagersnamecoapplicant')">required</span>
          </div>
          <div>
            <label for="previoustelephonecoapplicant"> {{telephoneplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="telephoneplaceholder"
              v-model="previoustelephonecoapplicant"
              id="previoustelephonecoapplicant"
              name="previoustelephonecoapplicant"
              @input="updateTelephone"
              data-vv-name="previoustelephonecoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previoustelephonecoapplicant')">required</span>
          </div>
          <div>
            <label for="previousaddress1coapplicant"> {{address1placeholder}}</label>
            <input
              class="form-control"
              :placeholder="address1placeholder"
              v-model="previousaddress1coapplicant"
              id="previousaddress1coapplicant"
              name="previousaddress1coapplicant"
              @input="updateAddress"
              data-vv-name="previousaddress1coapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousaddress1coapplicant')">required</span>
          </div>
          <div>
            <label for="previouscitycoapplicant"> {{cityplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="cityplaceholder"
            v-model="previouscitycoapplicant"
            id="previouscitycoapplicant"
            name="previouscitycoapplicant"
            @input="updateCity"
            data-vv-name="previouscitycoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previouscitycoapplicant')">required</span>
          </div>
          <div>
            <label for="previousstatecoapplicant"> {{stateplaceholder}}</label>
            <b-form-select name="previousstatecoapplicant" id="previousstatecoapplicant" v-model="previousstatecoapplicant" @change="updateState"
                           data-vv-name="previousstatecoapplicant" v-validate="rules"
            >
              <option v-for="(item, index) in states" @value="index" :key="index">{{item}}</option>
            </b-form-select>
            <span style="color: darkred" v-show="errors.has('previousstatecoapplicant')">required</span>
          </div>
          <div>
            <label for="previouszipcoapplicant"> {{zipplaceholder}}</label>
            <input
            class="form-control"
            :placeholder="zipplaceholder"
            v-model="previouszipcoapplicant"
            id="previouszipcoapplicant"
            name="previouszipcoapplicant"
            @input="updateZip"
            v-mask="'#####'"
            data-vv-name="previouszipcoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previouszipcoapplicant')">required</span>
          </div>
          <div>
            <label for="previousrentamountcoapplicant"> {{rentmortageamountplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="rentmortageamountplaceholder"
              v-model="previousrentamountcoapplicant"
              id="previousrentamountcoapplicant"
              name="previousrentamountcoapplicant"
              @input="updateRentAmount"
              data-vv-name="previousrentamountcoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousrentamountcoapplicant')">required</span>
          </div>
          <div>
            <label for="previousmoveindatecoapplicant"> {{moveindateplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="previousmoveindatecoapplicant" v-model="previousmoveindatecoapplicant"
                               data-vv-name="previousmoveindatecoapplicant" v-validate="rules"
                               @input="updateMoveInDate"
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="previousmoveindatecoapplicantyear" v-model="previousmoveindatecoapplicantyear"
                               data-vv-name="previousmoveindatecoapplicantyear" v-validate="rules"
                               @input="updateMoveInDateYear"
                >
                  <option value="0">Year:</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('previousmoveindatecoapplicant')">required</span>
          </div>
          <div>
            <label for="previousmoveoutdatecoapplicant"> {{moveoutdateplaceholder}}</label>
            <b-row>
              <b-col>Month</b-col>
              <b-col>Year</b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-select id="previousmoveoutdatecoapplicant" v-model="previousmoveoutdatecoapplicant"
                               data-vv-name="previousmoveoutdatecoapplicant" v-validate="rules"
                               @input="updateMoveOutDate"
                >
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </b-form-select>
              </b-col>
              <b-col>
                <b-form-select id="previousmoveoutdatecoapplicantyear" v-model="previousmoveoutdatecoapplicantyear"
                               data-vv-name="previousmoveoutdatecoapplicantyear" v-validate="rules"
                               @input="updateMoveOutDateYear"
                >
                  <option value="0">Year:</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </b-form-select>
              </b-col>
            </b-row>
            <span style="color: darkred" v-show="errors.has('previousmoveoutdatecoapplicant')">required</span>
          </div>
          <div>
            <label for="previousreasonforleavingcoapplicant"> {{reasonforleavingplaceholder}}</label>
            <input
              class="form-control"
              :placeholder="reasonforleavingplaceholder"
              v-model="previousreasonforleavingcoapplicant"
              id="previousreasonforleavingcoapplicant"
              name="previousreasonforleavingcoapplicant"
              @input="updateReasonForLeaving"
              data-vv-name="previousreasonforleavingcoapplicant" v-validate="rules"
            >
            <span style="color: darkred" v-show="errors.has('previousreasonforleavingcoapplicant')">required</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .danger {
    border: 1px solid red;
  }
</style>

<script>

  export default {
    props: ['address'],
    inject: ['$validator'],
    data () {
      return {
        id: this.address.id,
        checkednopreviousapartmentcoapplicant: this.$store.getters.step3checkednopreviousapartmentcoapplicant,
        previousapartmentnamecoapplicant: this.$store.getters.step3previousaddressapartmentname2,
        previousmanagersnamecoapplicant: this.$store.getters.step3previousaddressmanagersname2,
        previoustelephonecoapplicant: this.$store.getters.step3previousaddresstelephone2,
        previousaddress1coapplicant: this.$store.getters.step3previousaddressaddress12,
        previouscitycoapplicant: this.$store.getters.step3previousaddresscity2,
        previousstatecoapplicant: this.$store.getters.step3previousaddressstate2,
        previouszipcoapplicant: this.$store.getters.step3previousaddresszip2,
        previousrentamountcoapplicant: this.$store.getters.step3previousaddressrentamount2,
        previousmoveindatecoapplicant: this.$store.getters.step3previousaddressmoveindate2,
        previousmoveindatecoapplicantyear: this.$store.getters.step3previousaddressmoveindate2year,
        previousmoveoutdatecoapplicant: this.$store.getters.step3previousaddressmoveoutdate2,
        previousmoveoutdatecoapplicantyear: this.$store.getters.step3previousaddressmoveoutdate2year,
        previousreasonforleavingcoapplicant: this.$store.getters.step3previousaddressreasonforleaving2,
        previousapartmentnameplaceholder: this.$i18n.t('step3previousaddresspreviousapartmentname'),
        managersnameplaceholder: this.$i18n.t('step3previousaddressmanagersname'),
        telephoneplaceholder: this.$i18n.t('step3previousaddresstelephone'),
        address1placeholder: this.$i18n.t('step3previousaddressaddress1'),
        cityplaceholder: this.$i18n.t('step3previousaddresscity'),
        stateplaceholder: this.$i18n.t('step3previousaddressstate'),
        zipplaceholder: this.$i18n.t('step3previousaddresszip'),
        rentmortageamountplaceholder: this.$i18n.t('step3previousaddressrentmortgageamount'),
        moveindateplaceholder: this.$i18n.t('step3previousaddressmoveindate'),
        moveoutdateplaceholder: this.$i18n.t('step3previousaddressmoveoutdate'),
        reasonforleavingplaceholder: this.$i18n.t('step3previousaddressreasonforleaving'),
        states: {
                  'AL': 'Alabama',
                  'AK': 'Alaska',
                  'AS': 'American Samoa',
                  'AZ': 'Arizona',
                  'AR': 'Arkansas',
                  'CA': 'California',
                  'CO': 'Colorado',
                  'CT': 'Connecticut',
                  'DE': 'Delaware',
                  'DC': 'District Of Columbia',
                  'FM': 'Federated States Of Micronesia',
                  'FL': 'Florida',
                  'GA': 'Georgia',
                  'GU': 'Guam',
                  'HI': 'Hawaii',
                  'ID': 'Idaho',
                  'IL': 'Illinois',
                  'IN': 'Indiana',
                  'IA': 'Iowa',
                  'KS': 'Kansas',
                  'KY': 'Kentucky',
                  'LA': 'Louisiana',
                  'ME': 'Maine',
                  'MH': 'Marshall Islands',
                  'MD': 'Maryland',
                  'MA': 'Massachusetts',
                  'MI': 'Michigan',
                  'MN': 'Minnesota',
                  'MS': 'Mississippi',
                  'MO': 'Missouri',
                  'MT': 'Montana',
                  'NE': 'Nebraska',
                  'NV': 'Nevada',
                  'NH': 'New Hampshire',
                  'NJ': 'New Jersey',
                  'NM': 'New Mexico',
                  'NY': 'New York',
                  'NC': 'North Carolina',
                  'ND': 'North Dakota',
                  'MP': 'Northern Mariana Islands',
                  'OH': 'Ohio',
                  'OK': 'Oklahoma',
                  'OR': 'Oregon',
                  'PW': 'Palau',
                  'PA': 'Pennsylvania',
                  'PR': 'Puerto Rico',
                  'RI': 'Rhode Island',
                  'SC': 'South Carolina',
                  'SD': 'South Dakota',
                  'TN': 'Tennessee',
                  'TX': 'Texas',
                  'UT': 'Utah',
                  'VT': 'Vermont',
                  'VI': 'Virgin Islands',
                  'VA': 'Virginia',
                  'WA': 'Washington',
                  'WV': 'West Virginia',
                  'WI': 'Wisconsin',
                  'WY': 'Wyoming'
                }
      }
    },
    computed: {
      emptyName () {
        return this.name === ''
      },
      rules () {
        return !this.checkednopreviousapartmentcoapplicant ? 'required' : ''
      },
      years () {
        const year = new Date(new Date().setFullYear(new Date().getFullYear() + 11)).getFullYear()
        console.log('year')
        console.log(year)
        return Array.from({length: year - 1970}, (value, index) => 1970 + index)
      }
    },
    methods: {
      checkedNoPreviousApartmentCoApplicant () {
        this.$store.dispatch('updateStep3CheckedNoPreviousApartmentCoApplicant', this.checkednopreviousapartmentcoapplicant)
      },
      updateTelephone (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddresstelephone2', occup)
      },
      updateManagersname (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddressmanagersname2', occup)
      },
      updateApartmentName (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddressapartmentname2', occup)
      },
      updateAddress (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddressaddress12', occup)
      },
      updateCity (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddresscity2', occup)
      },
      updateState (event) {
        const occup = event
        this.$store.dispatch('updateStep3previousaddressstate2', occup)
      },
      updateZip (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddresszip2', occup)
      },
      updateRentAmount (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddressrentamount2', occup)
      },
      updateMoveInDate (event) {
        const occup = event
        this.$store.dispatch('updateStep3previousaddressmoveindate2', occup)
      },
      updateMoveInDateYear (event) {
        const occup = event
        this.$store.dispatch('updateStep3previousaddressmoveindate2year', occup)
      },
      updateMoveOutDate (event) {
        const occup = event
        this.$store.dispatch('updateStep3previousaddressmoveoutdate2', occup)
      },
      updateMoveOutDateYear (event) {
        const occup = event
        this.$store.dispatch('updateStep3previousaddressmoveoutdate2year', occup)
      },
      updateReasonForLeaving (event) {
        const occup = event.target.value
        this.$store.dispatch('updateStep3previousaddressreasonforleaving2', occup)
      }
    }
  }
</script>
